import { Pixel } from "./Pixel";
import { SetPixelRequest } from "./SetPixelRequest";

export class PixelsService {
  async getBoardImage(): Promise<Blob> {
    const response = await fetch("/v1/pixels");
    throwIfNotOk(response);
    return response.blob();
  }

  async getPixel(pixelX: number, pixelY: number): Promise<Pixel> {
    const response = await fetch(`/v1/pixels/${pixelX}.${pixelY}`);
    throwIfNotOk(response);
    return response.json();
  }

  async setPixel(
    pixelX: number,
    pixelY: number,
    setPixelRequest: SetPixelRequest
  ): Promise<void> {
    const response = await fetch(`/v1/pixels/${pixelX}.${pixelY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(setPixelRequest),
    });
    throwIfNotOk(response);
  }
}

function throwIfNotOk(response: Response): void {
  if (!response.ok) {
    throw new Error(`${response.status} error in response`);
  }
}
