import { PixelsService } from "../api/PixelsService";
import { Rgba } from "../api/Rgba";
import { SetPixelRequest } from "../api/SetPixelRequest";
import * as StompJs from "@stomp/stompjs";
import { Pixel } from "../api/Pixel";

export interface BoardInitEvent {
  type: "init";
  boardImage: Blob;
}

export interface BoardUpdateEvent {
  type: "update";
  pixelX: number;
  pixelY: number;
  rgba: Rgba;
}

export type BoardEvent = BoardInitEvent | BoardUpdateEvent;

export type SubscribeFunction = (event: BoardEvent) => void;
export type UnsubscribeFunction = () => void;

export enum InitState {
  UNINITIALIZED,
  INITIALIZING,
  INITIALIZED,
}

export class BoardController {
  private static readonly WEBSOCKET_PATH = "/constellation/ws";

  private pixelsService: PixelsService;
  private subscriptions: SubscribeFunction[];
  private initState: InitState;
  private stompClient: StompJs.Client;

  constructor() {
    this.pixelsService = new PixelsService();
    this.subscriptions = [];
    this.stompClient = new StompJs.Client({
      brokerURL: relativeWebsocketUrl(BoardController.WEBSOCKET_PATH),
      onConnect: (frame) => {
        console.log("Stomp connected", frame);
        this.stompClient.subscribe("/topic/pixels", this.handleStompMessage);
      },
      onDisconnect: (frame) => console.log("Stomp disconnected", frame),
      onStompError: (frame) => console.log("Stomp error", frame),
    });
    this.initState = InitState.UNINITIALIZED;
  }

  public initialized(): InitState {
    return this.initState;
  }

  public async init(): Promise<void> {
    if (
      this.initState === InitState.INITIALIZED ||
      this.initState === InitState.INITIALIZING
    ) {
      console.log("Board controller already initialized or initializing");
      return;
    }
    this.initState = InitState.INITIALIZING;
    this.stompClient.activate();
    const boardImage = await new PixelsService().getBoardImage();
    this.initState = InitState.INITIALIZED;
    this.notifyListeners({ type: "init", boardImage });
  }

  public cleanup(): void {
    this.stompClient.deactivate();
    this.initState = InitState.UNINITIALIZED;
  }

  public async setPixel(
    pixelX: number,
    pixelY: number,
    setPixelRequest: SetPixelRequest
  ): Promise<void> {
    this.throwIfNotInitialized();
    await this.pixelsService.setPixel(pixelX, pixelY, setPixelRequest);
    this.notifyListeners({
      type: "update",
      pixelX,
      pixelY,
      rgba: setPixelRequest.rgba,
    });
  }

  public subscribe(callback: SubscribeFunction): UnsubscribeFunction {
    this.subscriptions.push(callback);
    return () => this.subscriptions.filter((fn) => fn !== callback);
  }

  private notifyListeners(event: BoardEvent) {
    this.subscriptions.forEach((callback) => callback(event));
  }

  private handleStompMessage = (message: StompJs.IMessage) => {
    const pixel: Pixel = JSON.parse(message.body);
    this.notifyListeners({
      type: "update",
      pixelX: pixel.pixelX,
      pixelY: pixel.pixelY,
      rgba: pixel.rgba,
    });
  };

  private throwIfNotInitialized() {
    if (this.initState !== InitState.INITIALIZED) {
      throw new Error("Board is not yet initialized");
    }
  }
}

function relativeWebsocketUrl(relativePath: string): string {
  const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
  return `${protocol}//${window.location.host}${relativePath}`;
}
