import { isEqual } from "lodash-es";
import { Rgba } from "../api/Rgba";
import { COLORS } from "../constants";
import styles from "./ColorPicker.module.scss";
import ColorSquare from "./ColorSquare";

interface ColorPickerProps {
  selectedColor: Rgba;
  onSelectColor: (color: Rgba) => void;
}

function ColorPicker({ selectedColor, onSelectColor }: ColorPickerProps) {
  return (
    <div className={styles["color-picker"]}>
      {COLORS.map((color) => (
        <ColorSquare
          key={`${color.red}-${color.green}-${color.blue}-${color.alpha}`}
          color={color}
          active={isEqual(selectedColor, color)}
          onSelectColor={onSelectColor}
        />
      ))}
    </div>
  );
}

export default ColorPicker;
