import { useCallback } from "react";
import { Rgba } from "../api/Rgba";
import { rgbaStyle } from "../utils/rgbaStyle";
import styles from "./ColorSquare.module.scss";
import classnames from "classnames";

interface ColorSquareProps {
  color: Rgba;
  active: boolean;
  onSelectColor: (color: Rgba) => void;
}

function ColorSquare({ color, active, onSelectColor }: ColorSquareProps) {
  const handleClick = useCallback(
    () => onSelectColor(color),
    [onSelectColor, color]
  );
  return (
    <div
      className={classnames(styles["color-square"], {
        [styles.active]: active,
      })}
      style={{
        backgroundColor: rgbaStyle(
          color.red,
          color.green,
          color.blue,
          color.alpha
        ),
      }}
      onClick={handleClick}
    />
  );
}

export default ColorSquare;
