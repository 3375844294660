import { useCallback, useState } from "react";
import { BOARD_CANVAS_ID, BOARD_SIZE, COLORS } from "../constants";
import { useBoardController } from "../hooks/useBoardController";
import { useBoardCamera } from "../hooks/useBoardCamera";
import { rgbaStyle } from "../utils/rgbaStyle";
import styles from "./Board.module.scss";
import { BoardInitEvent, BoardUpdateEvent } from "./BoardController";
import BoardToolbar from "./BoardToolbar";
import { Rgba } from "../api/Rgba";
import { Intent, ProgressBar } from "@blueprintjs/core";

function Board() {
  const [loading, setLoading] = useState(true);

  const handleBoardInitEvent = useCallback((event: BoardInitEvent) => {
    const ctx = getCanvasContext(BOARD_CANVAS_ID);
    if (ctx == null) {
      return;
    }

    const imageUrl = URL.createObjectURL(event.boardImage);
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      URL.revokeObjectURL(imageUrl);
      setLoading(false);
    };
    image.src = imageUrl;
  }, []);

  const handleBoardUpdateEvent = useCallback((event: BoardUpdateEvent) => {
    const ctx = getCanvasContext(BOARD_CANVAS_ID);
    if (ctx == null) {
      return;
    }

    // TODO(tleung): Batch updates from websocket and apply with putImageData in
    // requestAnimationFrame to handle high scale events.
    drawPixel(ctx, event.pixelX, event.pixelY, event.rgba);
  }, []);

  const boardController = useBoardController({
    onBoardInitEvent: handleBoardInitEvent,
    onBoardUpdateEvent: handleBoardUpdateEvent,
  });

  const [color, setColor] = useState(COLORS[0]);

  const setPixel = useCallback(
    (pixelX: number, pixelY: number) =>
      boardController.setPixel(pixelX, pixelY, {
        rgba: color,
        userName: "Tim",
      }),
    [boardController, color]
  );

  const {
    zoom,
    offset,
    zoomIn,
    zoomOut,
    panLeft,
    panRight,
    panUp,
    panDown,
    reset,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
    onWheel: handleWheel,
  } = useBoardCamera({ canvasId: BOARD_CANVAS_ID, onPixelClick: setPixel });

  return (
    <div className={styles["board-container"]}>
      <BoardToolbar
        color={color}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        panLeft={panLeft}
        panRight={panRight}
        panUp={panUp}
        panDown={panDown}
        reset={reset}
        setColor={setColor}
      />
      <div className={styles["canvas-container"]}>
        {loading && (
          <div className={styles["loading-bar-container"]}>
            <ProgressBar
              className={styles["loading-bar"]}
              intent={Intent.PRIMARY}
              animate={true}
            />
          </div>
        )}
        <canvas
          id={BOARD_CANVAS_ID}
          style={{
            transform: `scale(${zoom}) translate(${offset[0]}px,${offset[1]}px)`,
          }}
          className={styles.canvas}
          width={BOARD_SIZE}
          height={BOARD_SIZE}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onWheel={handleWheel}
        />
      </div>
    </div>
  );
}

function getCanvasContext(id: string): CanvasRenderingContext2D | undefined {
  const canvas = document.getElementById(id);
  if (canvas == null || !(canvas instanceof HTMLCanvasElement)) {
    console.log("Could not find canvas element");
    return undefined;
  }
  const ctx = canvas.getContext("2d");
  if (ctx == null) {
    console.log("Context is not available");
    return undefined;
  }
  return ctx;
}

function drawPixel(
  ctx: CanvasRenderingContext2D,
  pixelX: number,
  pixelY: number,
  rgba: Rgba
): void {
  ctx.fillStyle = rgbaStyle(rgba.red, rgba.green, rgba.blue, rgba.alpha);
  ctx.fillRect(pixelX, pixelY, 1, 1);
}

export default Board;
