import { Button, H1 } from "@blueprintjs/core";
import React, { ErrorInfo } from "react";
import styles from "./AppErrorBoundary.module.scss";

interface AppErrorBoundaryState {
  error: [Error, ErrorInfo] | undefined;
}

class AppErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  AppErrorBoundaryState
> {
  public state: AppErrorBoundaryState = { error: undefined };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: [error, errorInfo] });
  }

  render() {
    if (this.state.error) {
      const [error, errorInfo] = this.state.error;
      return (
        <div className={styles["error-boundary"]}>
          <H1>Error</H1>
          <pre className={styles.error}>
            {error.stack}
            {"\n"}
            {errorInfo.componentStack}
          </pre>
          <Button onClick={() => window.location.reload()}>
            🔄 Refresh page
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
