import { Button, ButtonGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Rgba } from "../api/Rgba";
import styles from "./BoardToolbar.module.scss";
import ColorPicker from "./ColorPicker";

interface BoardToolbarProps {
  color: Rgba;
  zoomIn: () => void;
  zoomOut: () => void;
  panLeft: () => void;
  panRight: () => void;
  panUp: () => void;
  panDown: () => void;
  reset: () => void;
  setColor: (color: Rgba) => void;
}

function BoardToolbar({
  color,
  zoomIn,
  zoomOut,
  panLeft,
  panRight,
  panUp,
  panDown,
  reset,
  setColor,
}: BoardToolbarProps) {
  return (
    <div className={styles["board-toolbar"]}>
      <ButtonGroup>
        <Button icon={IconNames.ZOOM_OUT} onClick={zoomOut} />
        <Button icon={IconNames.ZOOM_IN} onClick={zoomIn} />
        <Button icon={IconNames.ARROW_LEFT} onClick={panLeft} />
        <Button icon={IconNames.ARROW_RIGHT} onClick={panRight} />
        <Button icon={IconNames.ARROW_UP} onClick={panUp} />
        <Button icon={IconNames.ARROW_DOWN} onClick={panDown} />
        <Button icon={IconNames.RESET} onClick={reset} />
      </ButtonGroup>
      <ColorPicker selectedColor={color} onSelectColor={setColor} />
    </div>
  );
}

export default BoardToolbar;
