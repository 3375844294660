import { Rgba } from "./api/Rgba";
import { hexToRgba } from "./utils/hexToRgba";

export const BOARD_CANVAS_ID = "board";
export const BOARD_SIZE = 1000;
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 40;

export const COLORS: Rgba[] = [
  hexToRgba("#FFFFFF"),
  hexToRgba("#E4E4E4"),
  hexToRgba("#888888"),
  hexToRgba("#222222"),
  hexToRgba("#FFA7D1"),
  hexToRgba("#E50000"),
  hexToRgba("#E59500"),
  hexToRgba("#A06A42"),
  hexToRgba("#E5D900"),
  hexToRgba("#94E044"),
  hexToRgba("#02BE01"),
  hexToRgba("#00D3DD"),
  hexToRgba("#0083C7"),
  hexToRgba("#0000EA"),
  hexToRgba("#CF6EE4"),
  hexToRgba("#820080"),
];
