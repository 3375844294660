import { H1 } from "@blueprintjs/core";
import styles from "./AppHeader.module.scss";

function AppHeader() {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>🌠</div>
      <H1 className={styles.text}>Constellation</H1>
    </header>
  );
}

export default AppHeader;
