import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  WheelEvent,
  WheelEventHandler,
} from "react";
import { BOARD_SIZE, MAX_ZOOM, MIN_ZOOM } from "../constants";
import { usePreventWheelDefaultHack } from "./usePreventWheelDefaultHack";

export interface UseBoardCameraArgs {
  canvasId: string;
  onPixelClick: (pixelX: number, pixelY: number) => void;
}

export interface UseBoardCameraReturn {
  zoom: number;
  offset: [number, number];
  zoomIn: () => void;
  zoomOut: () => void;
  panLeft: () => void;
  panRight: () => void;
  panUp: () => void;
  panDown: () => void;
  reset: () => void;
  onMouseDown: MouseEventHandler<HTMLCanvasElement>;
  onMouseMove: MouseEventHandler<HTMLCanvasElement>;
  onMouseUp: MouseEventHandler<HTMLCanvasElement>;
  onWheel: WheelEventHandler<HTMLCanvasElement>;
}

export function useBoardCamera({
  canvasId,
  onPixelClick,
}: UseBoardCameraArgs): UseBoardCameraReturn {
  const [offset, setRawOffset] = useState<[number, number]>([0, 0]);
  const [zoom, setRawZoom] = useState(1);

  // Keep offset within bounds whenever zoom updates
  useEffect(() => {
    setRawOffset((offset) => clampOffset(offset, zoom));
  }, [zoom]);

  const setOffset = useCallback(
    (calculateOffset: (prevOffset: [number, number]) => [number, number]) => {
      setRawOffset((prevOffset) =>
        clampOffset(calculateOffset(prevOffset), zoom)
      );
    },
    [zoom]
  );
  const setZoom = useCallback((calculateZoom: (prevZoom: number) => number) => {
    setRawZoom((prevZoom) => clampZoom(calculateZoom(prevZoom)));
  }, []);

  const zoomIn = useCallback(() => {
    setZoom((zoom) => zoom + 1);
  }, [setZoom]);
  const zoomOut = useCallback(() => {
    setZoom((zoom) => zoom - 1);
  }, [setZoom]);
  const panLeft = useCallback(() => {
    setOffset((offset) => [offset[0] + 10, offset[1]]);
  }, [setOffset]);
  const panRight = useCallback(() => {
    setOffset((offset) => [offset[0] - 10, offset[1]]);
  }, [setOffset]);
  const panUp = useCallback(() => {
    setOffset((offset) => [offset[0], offset[1] + 10]);
  }, [setOffset]);
  const panDown = useCallback(() => {
    setOffset((offset) => [offset[0], offset[1] - 10]);
  }, [setOffset]);
  const reset = useCallback(() => {
    setRawZoom(1);
    setRawOffset([0, 0]);
  }, []);

  const mouseDownStart = useRef<{
    offset: [number, number];
    mouse: [number, number];
  }>();
  const handleMouseClick = useCallback(
    async (event: React.MouseEvent<HTMLCanvasElement>) => {
      const rect = document.getElementById(canvasId)?.getBoundingClientRect();
      if (rect == null) {
        console.log("Could not find dimensions of canvas element");
        return;
      }

      const pixelX = Math.floor((event.clientX - rect.left) / zoom);
      const pixelY = Math.floor((event.clientY - rect.top) / zoom);
      onPixelClick(pixelX, pixelY);
    },
    [canvasId, onPixelClick, zoom]
  );
  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      mouseDownStart.current = {
        offset,
        mouse: [event.clientX, event.clientY],
      };
    },
    [offset]
  );
  const handleMouseMove = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      if (mouseDownStart.current != null) {
        const { offset, mouse } = mouseDownStart.current;
        const movement = [
          (event.clientX - mouse[0]) / zoom,
          (event.clientY - mouse[1]) / zoom,
        ];
        requestAnimationFrame(() =>
          setOffset((_prevOffset) => [
            offset[0] + movement[0],
            offset[1] + movement[1],
          ])
        );
      }
    },
    [setOffset, zoom]
  );
  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      if (
        mouseDownStart.current != null &&
        Math.abs(event.clientX - mouseDownStart.current.mouse[0]) < 5 &&
        Math.abs(event.clientY - mouseDownStart.current.mouse[1]) < 5
      ) {
        handleMouseClick(event);
      }
      mouseDownStart.current = undefined;
    },
    [handleMouseClick]
  );

  const preventWheelDefault = usePreventWheelDefaultHack();
  const handleWheel = useCallback(
    (event: WheelEvent<HTMLCanvasElement>) => {
      preventWheelDefault();
      setZoom((zoom) => zoom - event.deltaY * 0.01);
    },
    [preventWheelDefault, setZoom]
  );

  return {
    zoom,
    offset,
    zoomIn,
    zoomOut,
    panLeft,
    panRight,
    panUp,
    panDown,
    reset,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
    onWheel: handleWheel,
  };
}

function clampZoom(zoom: number): number {
  return Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, zoom));
}

function clampOffset(offset: [number, number], zoom: number): [number, number] {
  return [
    clampOffsetComponent(offset[0], zoom),
    clampOffsetComponent(offset[1], zoom),
  ];
}

function clampOffsetComponent(offsetComponent: number, zoom: number): number {
  const maxOffsetMagnitude = (BOARD_SIZE * (zoom - 1)) / (2 * zoom);
  return Math.min(
    maxOffsetMagnitude,
    Math.max(-maxOffsetMagnitude, offsetComponent)
  );
}
