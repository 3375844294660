import Board from "../board/Board";
import styles from "./App.module.scss";
import AppErrorBoundary from "./AppErrorBoundary";
import AppHeader from "./AppHeader";

function App() {
  return (
    <AppErrorBoundary>
      <div className={styles.app}>
        <div className={styles["app-background"]}>
          <div className={styles.stars} />
          <div className={styles.stars2} />
          <div className={styles.stars3} />
        </div>
        <div className={styles["app-content"]}>
          <AppHeader />
          <Board />
        </div>
      </div>
    </AppErrorBoundary>
  );
}

export default App;
