import { useCallback, useEffect, useMemo } from "react";
import {
  BoardController,
  BoardEvent,
  BoardInitEvent,
  BoardUpdateEvent,
} from "../board/BoardController";
import { assertNever } from "../utils/assertNever";

export interface UseBoardControllerArgs {
  onBoardInitEvent: (event: BoardInitEvent) => void;
  onBoardUpdateEvent: (event: BoardUpdateEvent) => void;
}

export function useBoardController({
  onBoardInitEvent,
  onBoardUpdateEvent,
}: UseBoardControllerArgs): BoardController {
  const boardController = useMemo(() => new BoardController(), []);
  useEffect(() => {
    boardController.init();
    return () => boardController.cleanup();
  }, [boardController]);

  const handleBoardEvent = useCallback(
    (event: BoardEvent) => {
      if (event.type === "init") {
        onBoardInitEvent(event);
      } else if (event.type === "update") {
        onBoardUpdateEvent(event);
      } else {
        assertNever(event);
      }
    },
    [onBoardInitEvent, onBoardUpdateEvent]
  );

  useEffect(() => {
    const unsubscribe = boardController.subscribe(handleBoardEvent);
    return unsubscribe;
  }, [boardController, handleBoardEvent]);

  return boardController;
}
